import "./product_list.scss"
;(function ($) {
  "use strict"

  $("#products-page-next").click(function (event) {
    const self = $(this),
      nextPage = self.data("page"),
      maxPage = self.data("max-page"),
      formData = $("#filter-form").serialize()

    event.preventDefault()

    $.get(
      window.location.pathname,
      formData + "&page=" + nextPage,
      function (data) {
        const productRows = $.parseHTML(data, document, true)
        $(".row-products:last").after(productRows)
        window.ga4.initNextPageMeasurement()

        if (parseInt(nextPage) === parseInt(maxPage)) {
          self.remove()
        } else {
          self.data("page", nextPage + 1)
        }
      },
      "html"
    )
  })
})(jQuery)

onReady(() => {
  /*
   * Výmena obrázku značky pri príchode alebo odchode kurzorom.
   */

  /**
   * Vymení aktívny obrázok za neaktívny a naopak.
   * @param img
   */
  const flipImageSrc = (img) => {
    if (img.getAttribute("src") === img.dataset.srcActive) {
      img.setAttribute("src", img.dataset.srcInactive)
    } else {
      img.setAttribute("src", img.dataset.srcActive)
    }
  }

  document
    .querySelectorAll(".desktop-product-filter__brand-link img")
    .forEach((img) => {
      img.addEventListener("mouseenter", () => flipImageSrc(img))
      img.addEventListener("mouseleave", () => flipImageSrc(img))
    })
})
